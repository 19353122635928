export function MenuComponent(props) {
    return (
        <button
            onClick={() => {
                props.handleClick(props.id);
            }}
            className={`mb-5 w-[150px] h-[180px] rounded-xl flex flex-col items-center justify-center overflow-hidden hover:bg-secondary ${
                props.active ? "bg-secondary" : "bg-slate-100"
            }`}
        >
            <div className="w-[120px] h-[120px] mt-[15px] rounded-xl overflow-hidden flex justify-center items-center">
                <img src={props.image} className="w-full" />
            </div>
            <div className="grow w-full text-center px-5 flex justify-center items-center">
                <h4 className="font-bold text-[18px] line-clamp-2 leading-none">
                    {props.name}
                </h4>
            </div>
        </button>
    );
}
