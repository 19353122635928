import { useQuery } from "@apollo/client";
import { ProductComponent } from "./ProductComponent";
import { GET_PRODUCTS } from "src/graphql/query/Product";
import { PopUpProductComponent } from "./PopUpProductComponent";
import { useContext, useState } from "react";
import { CartContext } from "src/context/CartContext";
import { LoadingComponent } from "src/ui/components/loading/loading-component";
import { ErrorComponent } from "src/ui/components/error/error-component";

export function SubMenuComponent({ id, name, menuId }) {
    const {
        loading,
        error,
        data: dataProducts,
    } = useQuery(GET_PRODUCTS, { variables: { submenuId: id } });

    const { cart, setCart } = useContext(CartContext);

    const [productActive, setProductActive] = useState(null);

    if (loading) return <LoadingComponent />;
    if (error) return <ErrorComponent />;

    return (
        <div className="">
            {productActive != null ? (
                <PopUpProductComponent
                    product={productActive}
                    handleClose={() => {
                        setProductActive(null);
                    }}
                    callback={({ count, notes }) => {
                        const data = {
                            ...productActive,
                            amount: count,
                            notes: notes,
                            menuId: menuId,
                            buy: true,
                            idForBuy: new Date().getTime(),
                        };
                        setCart([...cart, data]);
                        setProductActive(null);
                    }}
                />
            ) : null}

            <h3 className="f-h3">{name}</h3>

            <div className="flex flex-wrap gap-7 mt-5">
                {dataProducts?.products
                    .filter((product) => !product.name.startsWith("[HIDDEN_PRODUCT]")) // Tambahkan filter ini
                    .map((product, index) => {
                        return (
                            <ProductComponent
                                key={product.id}
                                id={product.id}
                                image={product.displayPic}
                                name={product.name}
                                price={product.price}
                                handleClick={() => {
                                    setProductActive(product);
                                }}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
